:root {
    --ripple-time: 16s;
}

.ripple-parent {
    position: relative;
    overflow: hidden;
    background: none;
}

.ripple-parent > * {
    z-index: 1;
}

.ripple {
    position: absolute;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.1s ease;
    z-index: 0;
    opacity: 0;
    background-color: var(--rb50);
}

.ripple-parent:hover .ripple {
    opacity: 0.4;
    transition: opacity 0.5s ease;
}

.ripple:not(.stop)::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
    top: 0;
    left: 0;
    animation: rainbow-bg infinite var(--ripple-time);
}

.ripple:not(.stop)::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    animation: rainbow-bg-backdrop infinite var(--ripple-time);
}

@keyframes rainbow-bg {
    0% {
        background-color: var(--rb0);
        transform: scale(0);
    }
    5% {
        background-color: var(--rb0);
        transform: scale(1.25);
    }
    9.99% {
        background-color: var(--rb0);
        transform: scale(1.25);
    }
    10% {
        background-color: var(--rb30);
        transform: scale(0);
    }
    15% {
        background-color: var(--rb30);
        transform: scale(1.25);
    }
    19.99% {
        background-color: var(--rb30);
        transform: scale(1.25);
    }
    20% {
        background-color: var(--rb80);
        transform: scale(0);
    }
    25% {
        background-color: var(--rb80);
        transform: scale(1.25);
    }
    29.99% {
        background-color: var(--rb80);
        transform: scale(1.25);
    }
    30% {
        background-color: var(--rb40);
        transform: scale(0);
    }
    35% {
        background-color: var(--rb40);
        transform: scale(1.25);
    }
    39.99% {
        background-color: var(--rb40);
        transform: scale(1.25);
    }
    40% {
        background-color: var(--rb70);
        transform: scale(0);
    }
    45% {
        background-color: var(--rb70);
        transform: scale(1.25);
    }
    49.99% {
        background-color: var(--rb70);
        transform: scale(1.25);
    }
    50% {
        background-color: var(--rb20);
        transform: scale(0);
    }
    55% {
        background-color: var(--rb20);
        transform: scale(1.25);
    }
    59.99% {
        background-color: var(--rb20);
        transform: scale(1.25);
    }
    60% {
        background-color: var(--rb90);
        transform: scale(0);
    }
    65% {
        background-color: var(--rb90);
        transform: scale(1.25);
    }
    69.99% {
        background-color: var(--rb90);
        transform: scale(1.25);
    }
    70% {
        background-color: var(--rb10);
        transform: scale(0);
    }
    75% {
        background-color: var(--rb10);
        transform: scale(1.25);
    }
    79.99% {
        background-color: var(--rb10);
        transform: scale(1.25);
    }
    80% {
        background-color: var(--rb60);
        transform: scale(0);
    }
    85% {
        background-color: var(--rb60);
        transform: scale(1.25);
    }
    89.99% {
        background-color: var(--rb60);
        transform: scale(1.25);
    }
    90% {
        background-color: var(--rb50);
        transform: scale(0);
    }
    95% {
        background-color: var(--rb50);
        transform: scale(1.25);
    }
    99.99% {
        background-color: var(--rb50);
        transform: scale(1.25);
    }
}

@keyframes rainbow-bg-backdrop {
    0% {
        background-color: var(--rb50);
    }
    4.99% {
        background-color: var(--rb50);
    }
    5% {
        background-color: var(--rb0);
    }
    14.99% {
        background-color: var(--rb0);
    }
    15% {
        background-color: var(--rb30);
    }
    24.99% {
        background-color: var(--rb30);
    }
    25% {
        background-color: var(--rb80);
    }
    34.99% {
        background-color: var(--rb80);
    }
    35% {
        background-color: var(--rb40);
    }
    44.99% {
        background-color: var(--rb40);
    }
    45% {
        background-color: var(--rb70);
    }
    54.99% {
        background-color: var(--rb70);
    }
    55% {
        background-color: var(--rb20);
    }
    64.99% {
        background-color: var(--rb20);
    }
    65% {
        background-color: var(--rb90);
    }
    74.99% {
        background-color: var(--rb90);
    }
    75% {
        background-color: var(--rb10);
    }
    84.99% {
        background-color: var(--rb10);
    }
    85% {
        background-color: var(--rb60);
    }
    94.99% {
        background-color: var(--rb60);
    }
    95% {
        background-color: var(--rb50);
    }
    100% {
        background-color: var(--rb50);
    }
}
