:root,
html[data-theme="light"] {
    --primary: #576dc6;
    --on-primary: #ffffff;
    --primary-container: #788ad3;
    --on-primary-container: #384887;

    --secondary: #87d164;
    --secondary-transparent: #87d1645b;
    --on-secondary: #ffffff;
    --secondary-container: #9be678;
    --on-secondary-container: #69a14f;

    --tertiary: #c68b57;
    --on-tertiary: #ffffff;
    --tertiary-container: #d3aa78;
    --on-tertiary-container: #876638;

    --error: #bf6565;
    --on-error: #ffffff;
    --error-container: #e18989;
    --on-error-container: #733939;

    --background: #ffffff;
    --background-accent: #f3f3f3;
    --font: #0a0a0a;

    --background-invert: #2f2f2f;
    --on-background-invert: #d8d8d8;

    --small-font: 0.75rem;
    --big-font: 1.4rem;

    --line: #f3f3f3;
    --hint: #a4a4a4;

    --border-radius: 12px;

    --highlight: #577dc3;

    --rb0: hsl(0, 75%, 60%);
    --rb10: hsl(36, 75%, 60%);
    --rb20: hsl(72, 75%, 60%);
    --rb30: hsl(108, 75%, 60%);
    --rb40: hsl(144, 75%, 60%);
    --rb50: hsl(180, 75%, 60%);
    --rb60: hsl(216, 75%, 60%);
    --rb70: hsl(252, 75%, 60%);
    --rb80: hsl(288, 75%, 60%);
    --rb90: hsl(324, 75%, 60%);
}

html[data-theme="dark"] {
    --background: #2f2f2f;
    --background-accent: #262626;
    --font: #d8d8d8;

    --line: #262626;
    --hint: #a4a4a4;

    --background-invert: #161616;
    --on-background-invert: #d8d8d8;
}

* {
    font-family: "Roboto", sans-serif;
    margin: 0;
    box-sizing: border-box;
}

body {
    background-color: var(--background);
    color: var(--font);
}
