:root {
    --bar-height: 80%;
    --summary-bar-height: 6px;
    --head-height: 100px;
    --connection-color: #888;
    --connection-color-same-x: #222;
}

html[data-theme='dark'] {
    --connection-color: #888;
    --connection-color-same-x: #bbb;
}

.gantt-root {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: row;
}

.gantt-ui-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.gantt-ui-wrapper.locked {
    user-select: none;
    pointer-events: none;
}

.gantt-tree {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;
    background-color: var(--background);
    z-index: 1;
}

.gantt-tree-column {
    flex-shrink: 0;
    overflow: hidden;
}

.gantt-tree-column-head {
    display: flex;
    align-items: flex-end;
    padding: 8px;
    background-color: var(--background-accent);
    min-height: var(--head-height);
    max-height: var(--head-height);
    font-weight: bold;
}

.gantt-tree-column-extend-head {
    width: 40px;
}

.gantt-tree-column-content {
    display: flex;
    flex-direction: column;
}

.gantt-tree-row {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px;
}

.gantt-tree-row-line {
    position: absolute;
    top: 4px;
    width: 1px;
    z-index: 1;
}

.gantt-tree-row-line::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50px;
    background-color: inherit;
    opacity: 0.2;
}

.gantt-tree-extend-row {
    justify-content: center;
    color: var(--primary);
}

.row {
    position: relative;
    border-bottom: 1px solid var(--line);
    width: 100%;
}

.gantt-exception {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: var(--secondary);
    opacity: 0.25;
}

.gantt-view {
    position: relative;
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.gantt-view-content-wrapper {
    height: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
}

.gantt-scroll-overlay {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    height: 66px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    pointer-events: none;
}

.gantt-scroll-overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    pointer-events: none;
    user-select: none;
    opacity: 0;
    transition: opacity 0.4s ease;
}

.gantt-scroll-overlay-background.visible {
    opacity: 0.8;
}

.gantt-scroll-overlay-background-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--line);
}

.gantt-scroll-overlay-background-strip {
    position: absolute;
    transform: translate(-50%);
    width: 2px;
    top: 16px;
    bottom: 16px;
    background-color: var(--line);
    border-radius: 1000px;
}

.lmin {
    left: 40%;
}

.l1 {
    left: 33.125%;
    top: 24px;
    bottom: 24px;
    opacity: 0.8;
}

.l2 {
    left: 26.25%;
    top: 24px;
    bottom: 24px;
    opacity: 0.8;
}

.l3 {
    left: 19.375%;
    top: 24px;
    bottom: 24px;
    opacity: 0.8;
}

.lmax {
    left: 12.5%;
    background-color: var(--secondary);
}

.rmin {
    right: 40%;
}

.r1 {
    right: 33.125%;
    top: 24px;
    bottom: 24px;
    opacity: 0.8;
}

.r2 {
    right: 26.25%;
    top: 24px;
    bottom: 24px;
    opacity: 0.8;
}

.r3 {
    right: 19.375%;
    top: 24px;
    bottom: 24px;
    opacity: 0.8;
}

.rmax {
    right: 12.5%;
    background-color: var(--secondary);
}

.gantt-scroll-overlay-thumb {
    position: absolute;
    top: 12px;
    bottom: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--line);
    left: 50%;
    transform: translateX(-50%);
    transition: background-color 0.2s ease, opacity 0.2s ease;
    user-select: none;
    cursor: grab;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--big-font);
    color: white;
    opacity: 0.6;
    pointer-events: all;
}

.gantt-scroll-overlay-prev,
.gantt-scroll-overlay-next {
    position: absolute;
    top: 12px;
    bottom: 12px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--line);
    transition: background-color 0.2s ease, opacity 0.2s ease;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--big-font);
    color: white;
    opacity: 0.6;
    pointer-events: all;
}

.gantt-scroll-overlay-prev:hover,
.gantt-scroll-overlay-next:hover {
    opacity: 1;
    background-color: var(--primary);
}

.gantt-scroll-overlay-prev {
    left: 12.5%;
    transform: translateX(-50%);
}

.gantt-scroll-overlay-next {
    right: 12.5%;
    transform: translateX(50%);
}

.nospeed {
    background-color: var(--line) !important;
}

.highspeed {
    background-color: var(--secondary) !important;
}

.gantt-scroll-overlay-thumb:not(.dragging) {
    transition: background-color 0.4s ease, opacity 0.4s ease, left 0.2s ease, scale 0.4s ease;
}

.gantt-scroll-overlay-thumb:hover,
.gantt-scroll-overlay-thumb.dragging {
    background-color: var(--primary);
    opacity: 1;
}

.gantt-scroll-overlay-thumb.dragging {
    cursor: grabbing;
}

.gantt-scroll-overlay-particle-canvas {
    position: absolute;
    /* width: 100%;
    height: 100%; */
    z-index: -1;
    pointer-events: none;
    user-select: none;
}

.gantt-view-head {
    display: flex;
    flex-direction: column;
    background-color: var(--background-accent);
    min-height: var(--head-height);
    max-height: var(--head-height);
}

.gantt-view-head-hours,
.gantt-view-head-days,
.gantt-view-head-months,
.gantt-view-head-years {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.gantt-view-head-hour,
.gantt-view-head-day,
.gantt-view-head-month,
.gantt-view-head-year {
    display: flex;
    overflow: hidden;
    border-right: 1px solid var(--line);
    border-top: 1px solid var(--line);
    justify-content: center;
    align-items: center;
}

.gantt-view-head-hour {
    writing-mode: vertical-rl;
}

.gantt-view-head:first-child {
    border-top: none;
}

.gantt-view-head-day {
    position: relative;
    flex-direction: column;
    padding: 4px;
}

.gantt-view-head-day.current-date::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 2px solid var(--primary);
}

.gantt-view-head-day-num {
    font-weight: bold;
}

.gantt-view-head-day-txt {
    font-size: var(--small-font);
    color: var(--hint);
}

.gantt-view-content {
    position: relative;
    width: 100%;
    flex: 1 1 0px;
    overflow: hidden;
}

.gantt-view-content-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: flex;
    flex-direction: row;
    user-select: none;
    pointer-events: none;
}

.gantt-view-content-background-line {
    height: 100%;
}

.gantt-view-content-background-line {
    border-right: 1px solid var(--line);
}

.gantt-resizer-wrapper {
    position: relative;
    flex-shrink: 0;
    width: 0;
    user-select: none;
}

.gantt-resizer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -8px;
    right: -8px;
    z-index: 1;
    cursor: col-resize;
}

.gantt-resizer:hover .gantt-resizer-line {
    width: 8px;
    left: calc(50% - 4px);

    background-color: var(--line);
}

.gantt-resizer-line {
    position: absolute;
    width: 6px;
    left: calc(50% - 3px);
    top: 0;
    bottom: 0;
    background-color: var(--background-accent);
    transition: width 0.2s ease, left 0.2s ease, background-color 0.2s ease;
    pointer-events: none;
}

.gantt-tree .gantt-resizer-line {
    width: 2px;
    left: calc(50% - 1px);
}

.gantt-tree .gantt-resizer:hover .gantt-resizer-line {
    width: 4px;
    left: calc(50% - 2px);
}

.gantt-view-row {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
}

.gantt-taskbar {
    position: absolute;
    height: var(--bar-height);
    background-color: var(--primary);
    /* border-radius: var(--border-radius); */
    border-radius: 8px;
}

.gantt-summarybar {
    position: absolute;
    height: var(--summary-bar-height);
    background-color: var(--primary);
}

.gantt-summarybar-before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: calc(var(--summary-bar-height) * 2) calc(var(--summary-bar-height) * 2) 0 0;
    border-color: var(--primary) transparent transparent transparent;
}

.gantt-summarybar-after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 calc(var(--summary-bar-height) * 2) calc(var(--summary-bar-height) * 2) 0;
    border-color: transparent var(--primary) transparent transparent;
}

.gantt-bar {
    transition: filter 0.2s ease;
    outline: 3px solid rgb(0, 0, 0, 0);
    z-index: 1;
}

.gantt-summarybar {
    overflow-y: visible;
}

.gantt-summarybar-before-after-container {
    position: relative;
    width: 100%;
    height: 200%;
    overflow-x: hidden;
}

.gantt-bar:hover {
    filter: brightness(120%);
    z-index: 2;
}

.gantt-bar.selected {
    outline: 3px solid var(--primary);
}

.ghost {
    opacity: 0.4;
}

.tracking {
    opacity: 0.9;
}

.gantt-line-overlay {
    position: absolute;
    top: 0;
    left: 0;
}

.gantt-line {
    position: absolute;
}

.gantt-line.mouseover {
    /* cursor: pointer; */
}

.gantt-line.mouseover .gantt-line-segment {
    border-color: black;
}

.gantt-line-segment-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gantt-line-segment {
    position: relative;
    transition: border-color 0.2s ease;
}

.gantt-line-segment.hline {
    border-top: 1px dashed var(--connection-color);
}

.gantt-line-segment.vline {
    border-left: 1px dashed var(--connection-color);
}

.gantt-line-segment.sameX {
    border-color: var(--connection-color-same-x);
}

.gantt-line-puffer-label {
    position: absolute;
    color: var(--connection-color);
    font-size: var(--small-font);
    transform: translateY(-50%);
    margin-left: 4px;
    margin-top: -2px;
    white-space: nowrap;
}

.gantt-action-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    max-height: 50px;
    padding: 4px;
    gap: 4px;
    background-color: var(--background-invert);
    color: var(--on-background-invert);
}

.gantt-action-input {
    background-color: var(--background-invert);
    border-radius: var(--border-radius);
    border: 1px solid var(--line);
    color: var(--on-background-invert);
    padding: 8px;
    font: inherit;
    font-size: 0.85rem;
}

label ~ .gantt-action-input {
    margin-left: 4px;
}

.gantt-action-input[type='date'] {
    width: 95px;
}

.gantt-action-input::-webkit-calendar-picker-indicator {
    display: none;
}

.gantt-action-input-suffix {
    margin-left: 4px;
}

.gantt-action-button {
    display: flex;
    flex-direction: column;
    min-height: 40px;
    max-height: 40px;
    aspect-ratio: 1/1;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.gantt-action-button.emphasize {
    background-color: var(--secondary);
}

.gantt-action-button:active {
    background-color: rgba(0, 0, 0, 0.1);
    transition: background-color 0.05s ease;
}

.gantt-action-button-icon {
    flex: 1.5;
    min-height: 60%;
    max-height: 60%;
}

.gantt-action-button-caption {
    flex: 1;
    font-size: var(--small-font);
    transition: flex 0.2s ease, overflow 0.2s ease;
}

.gantt-action-bar:not(.show-tooltips) .gantt-action-button-caption {
    flex: 0;
    overflow: hidden;
}

.action-bar-slider-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
}

.action-bar-slider-wrapper > * {
    pointer-events: none;
}

.action-bar-slider {
    position: relative;
    width: calc(100% - 10px);
    height: 2px;
    background-color: var(--on-background-invert);
    display: flex;
    justify-content: space-between;
    margin-bottom: 27px;
    margin-left: 5px;
}

.action-bar-slider .tick {
    width: 2px;
    height: 7px;
    background-color: inherit;
    position: relative;
}

.action-bar-slider .tick.selected::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
    background-color: var(--secondary);
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
}

.action-bar-slider .start-icon {
    position: absolute;
    left: 0;
    top: 12px;
    transform: translateX(-50%);
}

.action-bar-slider .end-icon {
    position: absolute;
    right: 0;
    top: 12px;
    transform: translateX(50%);
}

.react-tooltip {
    z-index: 9999;
    border-radius: var(--border-radius);
    background-color: var(--background-invert);
    color: var(--on-background-invert);
    padding: 4px 8px;
}

.gantt-info-display {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    background-color: var(--background-invert);
    color: var(--on-background-invert);
    border-radius: var(--border-radius);
    white-space: pre;
    padding: 8px;
}

.gantt-info-content-wrapper {
    position: relative;
    transition: width 0.2s ease, height 0.2s ease, opacity 0.2s ease-in, visibility 0.2s ease;
}

.gantt-info-display-wrapper {
    position: fixed;
    overflow: hidden;
    z-index: 10;
    pointer-events: none;
}

.gantt-info-display-wrapper:not(.visible) .gantt-info-content-wrapper {
    visibility: hidden;
}

.gantt-info-display-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 4px;
}

.gantt-info-display-captions {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.gantt-info-display-caption {
    font-weight: 600;
}

.gantt-info-display-caption:last-child {
    font-size: 1.2rem;
}

.gantt-info-display-caption:not(:last-child) {
    font-size: calc(1rem - ((1 * 0.2rem) * (var(--index, 0) + 1)));
}

.gantt-info-splitter {
    width: calc(100% - 16px);
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: 1px;
    background-color: var(--primary);
}

.gantt-info-field {
    display: block;
}

.gantt-info-field-caption {
    display: inline-block;
    font-weight: 600;
    min-width: 120px;
    max-width: 120px;
    vertical-align: top;
    white-space: normal;
}

.gantt-info-field-value {
    display: inline-block;
    margin-bottom: -4px;
    width: calc(100% - 120px);
}

.gantt-info-field-value.multiline {
    white-space: normal;
}

.gantt-date-line {
    position: absolute;
    border-left: 1px dashed var(--primary);
    top: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;
}

.gantt-date-line-caption {
    position: absolute;
    top: 0;
    bottom: 0;
    text-align: center;
    color: var(--primary);
    text-orientation: mixed;
    writing-mode: vertical-rl;
}

.gantt-row-jump {
    position: absolute;
    color: var(--primary);
    font-size: 1.75rem;
    z-index: 1;
    margin-top: var(--head-height);
    transform: translateY(-50%);
    opacity: 0.3;
    transition: opacity 0.2s ease;
}

.gantt-row-jump:hover {
    cursor: pointer;
    opacity: 1;
}

.gantt-row-jump.left {
    left: 8px;
}

.gantt-row-jump.right {
    right: 8px;
}

.gantt-info-static-display-wrapper .gantt-info-display {
    pointer-events: none;
    top: calc(100% + 5px);
    left: 0;
    transition: left 1s ease-in-out, top 1s ease-in-out, transform 1s ease-in-out;
}

.gantt-info-static-display-wrapper .gantt-info-display.end {
    left: 100%;
    transform: translate(-100%, 0);
}

.gantt-info-static-display-wrapper .gantt-info-display.end.info-top {
    top: 0;
    transform: translate(-100%, calc(-100% - 10px));
}

.gantt-info-static-display-wrapper .gantt-info-display:not(.end) {
    transform: translate(0, 0);
}

.gantt-info-static-display-wrapper .gantt-info-display:not(.end).info-top {
    top: 0;
    transform: translate(0, calc(-100% - 10px));
}

.gantt-view-row.focused, .gantt-tree-row.focused {
    z-index: 10;
    background-color: var(--secondary-transparent) !important;
    color: var(--on-secondary-container);
    font-weight: 600;
}
