.gantt-action-input[type='number'] {
    width: 40px;
}

.gantt-action-input[type='number']::-webkit-outer-spin-button,
.gantt-action-input[type='number']::-webkit-inner-spin-button {
    display: none;
}

.gantt-view-row.focused .gantt-taskbar {
    outline: 3px solid var(--secondary);
}

.gantt-view-row:not(.focused) .gantt-bar {
    opacity: 0.6;
}

.gantt-action-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 50px;
    max-height: 50px;
    padding: 4px;
    gap: 4px;
    background-color: var(--background-invert);
    color: var(--on-background-invert);
}

.gantt-action-bar > * {
    position: absolute;
    display: flex;
    align-items: center;
}

.gantt-action-bar .left {
    left: 4px;
}

.gantt-action-bar .middle {
    left: 50%;
    transform: translateX(-50%);
    gap: 4px;
}

.gantt-action-bar .right {
    right: 4px;
    gap: 8px;
}

.gantt-action-input.token {
    font-family: 'Consolas' !important;
    width: 500px;
}

.shopfloor-settings {
    position: absolute;
    top: calc(100% + 8px);
    right: 8px;
    background-color: inherit;
    z-index: 100;
    background-color: var(--background-invert);
    color: var(--on-background-invert);
    padding: 8px 16px;
    border-radius: var(--border-radius);
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 8px;
    outline: 2px solid var(--secondary);
}

.shopfloor-settings .gantt-action-button-wrapper {
    margin-top: 4px;
    align-self: end;
}

.progress-indicator {
    position: fixed;
    bottom: 16px;
    right: 16px;
    width: 50px;
    aspect-ratio: 1/1;
    background-color: var(--line);
    opacity: 0.6;
    border-radius: 50%;
}

.progress-indicator-blob {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    background-color: var(--primary);
    transition: background-color 0.5s ease;
    aspect-ratio: 1/1;
    width: 100%;
    border-radius: 50%;
    opacity: 0.6;
}

.row-label-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.article-tag, .activity-tag {
    padding: 8px;
    border-radius: 100px;
    position: relative;
    font-weight: normal !important;
}

.article-tag {
    background-color: #226996;
    color: white;
}

.activity-tag {
    background-color: #985D79;
    color: white;
}

.article-tag::after, .activity-tag::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.7rem;
    font-weight: normal !important;
    color: white;
    mix-blend-mode: soft-light;
}

.gantt-info-display .article-tag::after {
    content: 'Artikel';
}

.gantt-info-display .activity-tag::after {
    content: 'Tätigkeit';
}